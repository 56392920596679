<template>
  <div class="permisos">
    <Navbar v-if="keyNavbar != 0" :key="keyNavbar"></Navbar>
    <div class="permisos-right">
      <div class="permisos-top">
        <p class="permisos-title">Permisos BASF</p>
        <label class="permisos-usuarios-length"
          >{{ usuarios.length }} usuarios</label
        >
      </div>
      <div class="permisos-buscar-container">
        <div class="permisos-buscar">
          <input
            type="text"
            placeholder="Buscar"
            ref="buscarInput"
            @keyup="buscar"
          />
          <img src="../assets/img/buscar.png" />
        </div>
      </div>
      <div class="permisos-usuarios-container">
        <div class="permisos-usuario-row permisos-usuario-row-titulo">
          <label>Nombre de usuario</label>
          <label>C&O</label>
          <label>AC</label>
          <label>CC</label>
          <label>POG</label>
          <label>Pedidos</label>
        </div>
        <div
          v-for="usuario in usuarios"
          :key="usuario.id"
          class="permisos-usuario-row permisos-usuario-row-data"
        >
          <div class="permisos-usuario-nombre-container">
            <label class="permisos-usuario-nombre">{{ usuario.nombre }}</label>
            <label class="permisos-usuario-email">{{ usuario.email }}</label>
          </div>
          <select
            name="select"
            v-model="usuario.rolCYO"
            @change="onChange(usuario)"
            v-show="$usuario.rolCYO.rol == 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detalle }}
            </option>
          </select>
          <select
            name="select"
            v-model="usuario.rolClientes"
            @change="onChange(usuario)"
            v-show="$usuario.rolClientes && $usuario.rolClientes.rol == 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detalle }}
            </option>
          </select>
          <select
            name="select"
            v-model="usuario.rolCC"
            @change="onChange(usuario)"
            v-show="$usuario.rolCC.rol == 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detalle }}
            </option>
          </select>
          <select
            name="select"
            v-model="usuario.rolPOG"
            @change="onChange(usuario)"
            v-show="$usuario.rolPOG.rol == 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detalle }}
            </option>
          </select>
          <select
            name="select"
            v-model="usuario.rolPedidos"
            @change="onChange(usuario)"
            v-show="$usuario.rolPedidos && $usuario.rolPedidos.rol == 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detalle }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <Loading v-show="mostrarLoading"></Loading>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Loading from "@/components/Loading.vue";

export default {
  name: "permisos",
  components: { Navbar, Loading },
  data() {
    return {
      keyNavbar: 0,
      usuarios: [],
      usuariosOG: [],
      roles: [
        {
          rol: "null",
          detalle: "Sin Acceso",
        },
        {
          rol: "AccesoMercado",
          detalle: "Acceso Mercado",
        },
        {
          rol: "Agro",
          detalle: "Agro",
        },
        {
          rol: "Canje",
          detalle: "Canje",
        },
        {
          rol: "Cobranzas",
          detalle: "Cobranzas",
        },
        {
          rol: "Creditos",
          detalle: "Créditos",
        },
        {
          rol: "CropManager",
          detalle: "Crop Manager",
        },
        {
          rol: "CustomerCare",
          detalle: "Customer Care",
        },
        {
          rol: "Distribuidor",
          detalle: "Distribuidor",
        },
        {
          rol: "GerenteMarketing",
          detalle: "Gerente de Marketing",
        },
        {
          rol: "GerenteVentas",
          detalle: "Gerente de Ventas",
        },
        {
          rol: "Impuestos",
          detalle: "Impuestos",
        },
        {
          rol: "Marketing",
          detalle: "Marketing",
        },
        {
          rol: "Performance",
          detalle: "Performance",
        },
        {
          rol: "Ventas",
          detalle: "Ventas",
        },
      ],
      mostrarLoading: false
    };
  },
  created() {},
  mounted() {
    const that = this;
    this.$nextTick(() => {
      that.keyNavbar = Math.round(Math.random() * 1000);
    });
    this.getUsuarios();
  },
  methods: {
    getUsuarios() {
      this.mostrarLoading = true;
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/usuarios")
        .then(function (response) {
          response.data.forEach((u) => {
            u.rolCYO = u.rolCYO != null ? u.rolCYO.rol : "null";
            u.rolClientes = u.rolClientes != null ? u.rolClientes.rol : "null";
            u.rolCC = u.rolCC != null ? u.rolCC.rol : "null";
            u.rolPOG = u.rolPOG != null ? u.rolPOG.rol : "null";
            u.rolPedidos = u.rolPedidos != null ? u.rolPedidos.rol : "null";
          });
          that.usuarios = response.data;
          that.usuariosOG = response.data;
          that.mostrarLoading = false;
        })
        .catch(function () {
          that.mostrarLoading = false;
          // handle error
          if (
            window.location.pathname != "/login" &&
            window.location.pathname.split("/")[1] != "password" &&
            window.location.pathname.split("/")[3] != "password"
          ) {
            that.$router.push("/login");
          }
        });
    },
    update(payload, id) {
      console.log(payload);
      console.log(id);
    },
    onChange(u) {
      let usuario = JSON.parse(JSON.stringify(u));
      if (usuario.rolCYO != "null" && usuario.rolCYO != "SinAcceso") {
        usuario.rolCYO = {
          rol: usuario.rolCYO,
        };
      } else {
        usuario.rolCYO = null;
      }
      if (usuario.rolClientes != "null" && usuario.rolClientes != "SinAcceso") {
        usuario.rolClientes = {
          rol: usuario.rolClientes,
        };
      } else {
        usuario.rolClientes = null;
      }
      if (usuario.rolCC != "null" && usuario.rolCC != "SinAcceso") {
        usuario.rolCC = {
          rol: usuario.rolCC,
        };
      } else {
        usuario.rolCC = null;
      }
      if (usuario.rolPOG != "null" && usuario.rolPOG != "SinAcceso") {
        usuario.rolPOG = {
          rol: usuario.rolPOG,
        };
      } else {
        usuario.rolPOG = null;
      }
      if (usuario.rolPedidos != "null" && usuario.rolPedidos != "SinAcceso") {
        usuario.rolPedidos = {
          rol: usuario.rolPedidos,
        };
      } else {
        usuario.rolPedidos = null;
      }
      console.log(usuario);
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/usuario/permisos", usuario)
        .then(function () {})
        .catch(function () {
          // handle error
          if (
            window.location.pathname != "/login" &&
            window.location.pathname.split("/")[1] != "password" &&
            window.location.pathname.split("/")[3] != "password"
          ) {
            that.$router.push("/login");
          }
        });
    },
    buscar() {
      let filtroBuscar = this.$refs.buscarInput.value.toUpperCase();

      this.usuarios = this.usuariosOG.filter(
        (d) =>
          (d.nombre != null ? d.nombre : "")
            .toUpperCase()
            .indexOf(filtroBuscar) > -1 ||
          (d.email != null ? d.email : "").toUpperCase().indexOf(filtroBuscar) >
            -1
      );
    },
  },
};
</script>

<style scoped src="../assets/css/views/permisos.css"></style>